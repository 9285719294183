/* global languageSettings */
let cookieLang = window.Cookies.get('language_switched')
const siteLang = languageSettings.locale
const langsWithHref = languageSettings.slugs
const isLandingPage = languageSettings.isLandingPage
const viewerCountry = languageSettings.viewerCountry

function redirectUser() {
  if (isLandingPage === 1) {
    setLanguageCookie(siteLang)

    return
  }

  if (viewerCountry?.toLowerCase() === 'za') {
    setLanguageCookie('en-za')
    switchToLocale(['en-za'])

    return
  }

  setLanguageCookieFromParam()
  const outdatedLocales = ['fr', 'nl', 'it']
  if (outdatedLocales.includes(cookieLang)) {
    window.Cookies.remove('language_switched')
    cookieLang = undefined
  }

  if (!document.referrer.includes(window.location.host) && window.location.pathname.startsWith('/' + siteLang + '/')) {
    setLanguageCookie(siteLang)
  }

  if (!cookieLang) {
    const userLangs = navigator.languages.map((lang) => lang.substring(0, 2))
    switchToLocale(userLangs)
  }

  if (cookieLang && cookieLang !== siteLang) {
    switchToLocale([cookieLang])
  }
}

function switchToLocale(userLanguages) {
  for (const userLang of userLanguages) {
    if (isLanguageAllowed(userLang)) {
      if (userLang !== siteLang) {
        window.location.replace(langsWithHref[userLang] + getParams())
      }

      break
    }
  }
}

function isLanguageAllowed(lang) {
  const langs = langsWithHref ? Object.keys(langsWithHref) : []

  return langs.includes(lang)
}

function setLanguageCookie(localeLang) {
  if (localeLang && isLanguageAllowed(localeLang)) {
    cookieLang = localeLang

    window.Cookies.set('language_switched', localeLang, {
      expires: 90,
      sameSite: 'strict',
      secure: window.location.protocol === 'https:'
    })
  }
}

function setLanguageCookieFromParam() {
  const params = new URLSearchParams(window.location.search)
  const localeLang = params.get('lang')

  if (localeLang) {
    setLanguageCookie(localeLang)
    if (localeLang !== cookieLang) {
      window.location.replace('/' + getParams(false))
    }
  }
}

function getParams(deleteLang = true) {
  const params = new URLSearchParams(window.location.search)
  if (deleteLang) {
    params.delete('lang')
  }

  return params.toString() ? '?' + params.toString() : ''
}

function isCrawler() {
  const crawlers = [
    'googlebot',
    'bingbot',
    'slurp',
    'duckduckbot',
    'yandexbot',
    'sogou',
    'exabot',
    'facebot',
    'applebot',
    'baiduspider',
    'screaming frog'
  ]
  const userAgent = window.navigator.userAgent.toLowerCase()

  return crawlers.some((crawler) => userAgent.includes(crawler))
}

if (!isCrawler()) {
  redirectUser()
}
